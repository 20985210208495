
export type ObjectFieldTypes = {
    [key: string]: { [key: string]: string | string[] }
};

export type OpTypes = {
    [key: string]: string | string[]
};

export type ScalarLocations = {
 scalars: string[],
 inputObjectFieldTypes: ObjectFieldTypes;
 outputObjectFieldTypes: ObjectFieldTypes;
 operationMap: OpTypes;
};

export const scalarLocations : ScalarLocations = {
  "inputObjectFieldTypes": {
    "ActionInput": {
      "customFields": "Json",
      "donation": "DonationActionInput",
      "fields": "CustomFieldInput",
      "mtt": "MttActionInput"
    },
    "ActionPageInput": {
      "config": "Json"
    },
    "CampaignInput": {
      "actionPages": "ActionPageInput",
      "config": "Json",
      "mtt": "CampaignMttInput"
    },
    "ContactInput": {
      "address": "AddressInput",
      "nationality": "NationalityInput"
    },
    "DonationActionInput": {
      "payload": "Json"
    },
    "OrgInput": {
      "config": "Json"
    },
    "TargetInput": {
      "emails": "TargetEmailInput",
      "fields": "Json"
    }
  },
  "outputObjectFieldTypes": {
    "Action": {
      "actionPage": [
        "PrivateActionPage",
        "PublicActionPage"
      ],
      "campaign": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "contact": "Contact",
      "customFields": "Json",
      "donation": "Donation",
      "fields": "CustomField",
      "privacy": "Consent",
      "tracking": "Tracking"
    },
    "ActionCustomFields": {
      "customFields": "Json",
      "fields": "CustomField"
    },
    "CampaignStats": {
      "actionCount": "ActionTypeCount",
      "supporterCountByArea": "AreaCount",
      "supporterCountByOrg": "OrgCount"
    },
    "Confirm": {
      "creator": "User"
    },
    "ConfirmResult": {
      "actionPage": [
        "PrivateActionPage",
        "PublicActionPage"
      ],
      "campaign": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "Contact": {
      "publicKey": "KeyIds",
      "signKey": "KeyIds"
    },
    "Donation": {
      "payload": "Json"
    },
    "JoinOrgResult": {
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "OrgCount": {
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "Partnership": {
      "actionPages": [
        "PrivateActionPage",
        "PublicActionPage"
      ],
      "launchRequesters": "User",
      "launchRequests": "Confirm",
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "PrivateActionPage": {
      "campaign": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "config": "Json",
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "PrivateCampaign": {
      "actionPages": "PrivateActionPage",
      "actions": "PublicActionsResult",
      "config": "Json",
      "mtt": "CampaignMtt",
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ],
      "partnerships": "Partnership",
      "stats": "CampaignStats",
      "targets": [
        "PrivateTarget",
        "PublicTarget"
      ]
    },
    "PrivateOrg": {
      "actionPage": [
        "PrivateActionPage",
        "PublicActionPage"
      ],
      "actionPages": [
        "PrivateActionPage",
        "PublicActionPage"
      ],
      "campaign": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "campaigns": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "config": "Json",
      "key": "Key",
      "keys": "Key",
      "personalData": "PersonalData",
      "processing": "Processing",
      "services": "Service",
      "users": "OrgUser"
    },
    "PrivateTarget": {
      "emails": "TargetEmail",
      "fields": "Json"
    },
    "PublicActionPage": {
      "campaign": [
        "PrivateCampaign",
        "PublicCampaign"
      ],
      "config": "Json",
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    },
    "PublicActionsResult": {
      "list": "ActionCustomFields"
    },
    "PublicCampaign": {
      "actions": "PublicActionsResult",
      "config": "Json",
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ],
      "stats": "CampaignStats",
      "targets": [
        "PrivateTarget",
        "PublicTarget"
      ]
    },
    "PublicOrg": {
      "config": "Json"
    },
    "PublicTarget": {
      "fields": "Json"
    },
    "User": {
      "apiToken": "ApiToken",
      "roles": "UserRole"
    },
    "UserRole": {
      "org": [
        "PrivateOrg",
        "PublicOrg"
      ]
    }
  },
  "operationMap": {
    "acceptOrgConfirm": "ConfirmResult",
    "acceptUserConfirm": "ConfirmResult",
    "activateKey": "ActivateKeyResult",
    "addAction": "ContactReference",
    "addActionContact": "ContactReference",
    "addActionPage": [
      "PrivateActionPage",
      "PublicActionPage"
    ],
    "addCampaign": [
      "PrivateCampaign",
      "PublicCampaign"
    ],
    "addKey": "Key",
    "addOrg": [
      "PrivateOrg",
      "PublicOrg"
    ],
    "addOrgUser": "ChangeUserStatus",
    "addStripeObject": "Json",
    "addStripePaymentIntent": "Json",
    "addStripeSubscription": "Json",
    "copyActionPage": [
      "PrivateActionPage",
      "PublicActionPage"
    ],
    "copyCampaignActionPage": [
      "PrivateActionPage",
      "PublicActionPage"
    ],
    "deleteOrgUser": "DeleteUserResult",
    "generateKey": "KeyWithPrivate",
    "inviteOrgUser": "Confirm",
    "joinOrg": "JoinOrgResult",
    "launchActionPage": "LaunchActionPageResult",
    "linkActions": "ContactReference",
    "rejectOrgConfirm": "ConfirmResult",
    "rejectUserConfirm": "ConfirmResult",
    "requeueActions": "RequeueResult",
    "updateActionPage": [
      "PrivateActionPage",
      "PublicActionPage"
    ],
    "updateCampaign": [
      "PrivateCampaign",
      "PublicCampaign"
    ],
    "updateOrg": "PrivateOrg",
    "updateOrgProcessing": "PrivateOrg",
    "updateOrgUser": "ChangeUserStatus",
    "updateUser": "User",
    "upsertCampaign": [
      "PrivateCampaign",
      "PublicCampaign"
    ],
    "upsertService": "Service",
    "upsertTargets": "PrivateTarget",
    "actionPage": [
      "PrivateActionPage",
      "PublicActionPage"
    ],
    "campaign": [
      "PrivateCampaign",
      "PublicCampaign"
    ],
    "campaigns": [
      "PrivateCampaign",
      "PublicCampaign"
    ],
    "currentUser": "User",
    "exportActions": "Action",
    "org": "PrivateOrg",
    "users": "User",
    "actionPageUpserted": [
      "PrivateActionPage",
      "PublicActionPage"
    ]
  },
  "scalars": [
    "Json"
  ]
};
