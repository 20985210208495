import { create } from 'zustand';
import { addKeysToKeyStore, KeyStore, PublicKey, KeyPair } from "@proca/crypto";

interface CryptoState {
  generalError: Error | null;
  keyStore: KeyStore;
  setGeneralError: (error: Error) => void;
  addToKeyStore: (key: PublicKey, priv: string) => void;
  clearKeyStore: () => void;
  mergeUploadedKeyFile: (files: FileList) => Promise<void>;
}

export const useCryptoStore = create<CryptoState>((set) => ({
  generalError: null,
  keyStore: { filename: null, readFromFile: false, keys: [] }, // Initialize with an appropriate KeyStore structure

  setGeneralError: (error) => set({ generalError: error }),

  addToKeyStore: (key: PublicKey, priv: string) => {
    const keypair: KeyPair = { public: key.public, private: priv };

    set((state) => {
      const kc = state.keyStore;
      let found = false;
      const keys = kc.keys.map((k) => {
        if (k.public === keypair.public) {
          found = true;
          k.private = keypair.private; // Update the private key if it exists
        }
        return k;
      });
      if (!found) {
        keys.push(keypair); // Add the new keypair if it wasn't found
      }

      return { keyStore: { ...kc, keys } };
    });
  },

  clearKeyStore: () => set({ keyStore: { filename: null, readFromFile: false, keys: [] } }),

  mergeUploadedKeyFile: async (files: FileList) => {
    if (files.length === 0) return;
    const file = files[0];
    const text = await file.text();
    const keys = fixLegacyKeysMap(JSON.parse(text));

    set((state) => {
      const localKc: KeyStore = {
        ...state.keyStore,
        keys: [...state.keyStore.keys],
      };

      addKeysToKeyStore(keys, localKc); // Add uploaded keys to the existing keyStore

      return { keyStore: localKc };
    });
  },
}));

const fixLegacyKeysMap = (keys: any) => {
  const e = Object.entries(keys).map(([k, v]) => {
    if (typeof v === 'string') {
      return [k, { private: v }];
    } else {
      return [k, v];
    }
  });
  return Object.fromEntries(e);
};
