import { create } from 'zustand';
import { Client, CombinedError } from "@urql/core";
import { supabase, SupabaseSession } from 'src/lib/supabase';
import {
  AuthHeader,
  AuthHeaderFetcher,
  httpLink,
  scalarSerializers,
  tokenAuth,
  TokenAuth,
} from "@proca/api";
import createSerializeScalarsExchange from "urql-serialize-scalars-exchange";
import { errorMessages } from "src/lib/helpers";
import { scalarLocations } from "src/codegen/scalar-locations";
import { UserInfoFragment } from 'src/codegen/types';
import { setContext } from '@sentry/react';
import { queryClient } from "src/lib/server";

const scalarSerializerExchange = createSerializeScalarsExchange(scalarLocations || null, scalarSerializers);

class ProcaErrorException extends Error {
  error: CombinedError;
  constructor(error: CombinedError) {
    super(errorMessages(error));
    this.error = error;
    Object.setPrototypeOf(this, ProcaErrorException.prototype);
  }
}

export const makeClient = (apiUrl: string, auth?: TokenAuth): Client => {
  let authorization: AuthHeader | undefined;
  let fetchAuthorization: AuthHeaderFetcher | undefined;

  if (auth?.token) {
    authorization = tokenAuth(auth);
  } else {
    fetchAuthorization = () => auth?.token || null;
  }

  const client = httpLink(apiUrl, authorization || fetchAuthorization, {
    exchanges: [scalarSerializerExchange],
  });
  return client;
};

const orgNameForUser = (user: UserInfoFragment, current?: string): string | undefined => {
  if (current && user.roles.find((r) => r.org.name === current)) return current;
  const saved = localStorage.getItem("orgName");
  if (saved && user.roles.find((r) => r.org.name === saved)) return saved;
  return user.roles[0]?.org?.name;
};

// Define types for state and actions
type ProcaState = {
  hasSession: boolean;
  authenticated: boolean;
  session: SupabaseSession | null;
  client: Client;
  currentOrgName: string | null;
};

type ProcaActions = {
  setSSOSession: (session?: SupabaseSession | null) => void;
  clearSSOSession: () => void;
  signOut: () => Promise<void>;
  gotoSignIn: () => void;
  ensureSignIn: (session: SupabaseSession) => void;
  setProcaError: (error: CombinedError) => void;
  setCurrentOrgName: (orgName: string) => void;
  determineOrg: (user: UserInfoFragment) => void;
};

const initialSession = JSON.parse(localStorage.getItem("sb-vurrrokassxubbxlvufw-auth-token") || 'null');

// Initial state
const initialState: ProcaState = {
  hasSession: initialSession ? true : false,
  authenticated: false,
  session: initialSession,
  client: makeClient(process.env.REACT_APP_API_URL || 'https://api.proca.app/api',
    initialSession?
    { token: initialSession.access_token }
    : undefined),
  currentOrgName: null,
};

const signInUrl = "/auth/sign_in";

// Zustand store creation
export const useProcaStore = create<ProcaState & ProcaActions>((set, get) => ({
  ...initialState,

  setSSOSession: (session = null) => {
    set({
      session,
      client: makeClient(
        process.env.REACT_APP_API_URL || 'https://api.proca.app/api',
        session ? { token: session.access_token } : undefined
      ),
      hasSession: Boolean(session),
      authenticated: Boolean(session?.access_token),
    });
  },

  clearSSOSession: () => {
    set({
      session: null,
      client: makeClient(process.env.REACT_APP_API_URL || 'https://api.proca.app/api'),
      hasSession: false,
      authenticated: false,
    });
  },

  signOut: async () => {
    await supabase.auth.signOut();
    set({
      session: null,
      client: makeClient(process.env.REACT_APP_API_URL || 'https://api.proca.app/api'),
      hasSession: false,
      authenticated: false,
    });
    window.location.href = signInUrl;
  },

  gotoSignIn: () => {
    let returnTo = encodeURIComponent(window.location.pathname);

    if (window.location.search.indexOf("code") > -1) {
      returnTo += window.location.search;
    }
    window.location.href = signInUrl + "?return_to=" + returnTo;
    console.log(window.location.href = signInUrl + "?return_to=" + returnTo);
    return null;
  },

  ensureSignIn: (session) => {
    if (session) {
      set({
        session,
        client: makeClient(process.env.REACT_APP_API_URL || 'https://api.proca.app/api', { token: session.access_token }),
        hasSession: true,
        authenticated: Boolean(session.access_token),
      });
    } else if (window.location.hash === "") {
      const signInUrl = "/auth/sign_in";
      let returnTo = encodeURIComponent(window.location.pathname);

      if (window.location.search.indexOf("code") > -1) {
        returnTo += window.location.search;
      }
      window.location.href = `${signInUrl}?return_to=${returnTo}`;
    }
  },

  setProcaError: (error) => {
    console.error('Proca error:', error.message);
    throw new ProcaErrorException(error);
  },

  setCurrentOrgName: (orgName) => {
    if (typeof orgName !== 'string') {
      console.error("Tried to set org name to", orgName);
      throw new Error(`You tried to set app.state.currentOrgName to non-string: ${typeof orgName}`);
    }
    localStorage.setItem("orgName", orgName);
    setContext("org", { name: orgName });
    set({
      currentOrgName: orgName
    });

    queryClient.invalidateQueries({ queryKey: ["CurrentUserOrgs"] });
  },

  determineOrg: (user) => {
    const orgName = orgNameForUser(user, undefined);
    if (orgName) {
      localStorage.setItem("orgName", orgName);
    setContext("org", { name: orgName });
    set({
      currentOrgName: orgName
    });
    }
  },
}));
