import { Box, Button, Link, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { truncate } from "lodash";
import { ReactNode, FunctionComponent } from "react";
import { useProcaStore } from "src/store";
import EntryFrame from "src/components/layout/EntryFrame";

type Props = {
  children?: ReactNode;
};

const SignOut: FunctionComponent = () => {
  const { signOut } = useProcaStore();
  return (
    <Button>
      <Link onClick={() => signOut()}>Sign out</Link>
    </Button>
  );
};

export const LoadError = (props) => {
  let userMsg = (
    <Box m={3}>
      <Typography variant="body1" align="center">
        The techies have been informed. If you want to add more details about
        the error (eg what you were trying to do), you can send us a message by
        clicking on the link below
      </Typography>
    </Box>
  );
  if (props.error.name === "unauthorized_client") {
    userMsg = (
      <Box m={3}>
        <Typography variant="body1">{props.error.message};</Typography>
      </Box>
    );
  }
  if (props.error.message.indexOf("Current org undefined") > -1) {
    userMsg = (
      <Box m={3}>
        <p>
          Hello and welcome! Your user account is created and works.
          Unfortunately, we need to connect you with an organisation to support
          your campaign, and we can't find yours yet.
        </p>
        <Typography variant="body1">If you want to:</Typography>
        <ul>
          <li>
            <b>join a campaign</b>: visit again the invite link you received
          </li>
          <li>
            <b>join an organisation</b>: ask one of your team members to invite
            you
          </li>
          <li>start a new campaign or something else: contact us</li>
        </ul>
      </Box>
    );
  }

  const shortMessage = props.error.message.split(":")[0];
  const email = `mailto:support@fixthestatusquo.org?subject=Error on WE!&body=${encodeURIComponent(
    `[put extra information here]\n\n\n----- please keep the extra information below to help us understand better ----\nEVENT ID: ${
      props.eventId
    },\n${props.error.name.toUpperCase()}: ${shortMessage},\nINFO: ${truncate(
      props.componentStack,
      { length: 1000 }
    )}`
  )}`;
  const contactSupport = (
    <Link href={email}>
      <Typography variant="body1" align="center">
        Send us a message!
      </Typography>
    </Link>
  );

  return (
    <EntryFrame footer={<SignOut />}>
      <Typography variant="h4" align="center">
        There is a glitch in the machine, but humans will fix it!
      </Typography>
      {userMsg}
      {contactSupport}
    </EntryFrame>
  );
};

const errorHandler = (error, info, eventId) => {
  //we don't do anything, js already displayed the error
  if (true) return;
};

const LoadErrorBoundary: FunctionComponent<Props> = (props) => {
  return (
    <Sentry.ErrorBoundary fallback={LoadError} onError={errorHandler}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
};

export default LoadErrorBoundary;
