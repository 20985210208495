import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Grid2 as Grid, List, ListItem, ListItemIcon } from "@mui/material";
import { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import EntryFrame, { ProcaTopLeft } from "src/components/common/Dialog";
import { ReactComponent as Proca } from "src/images/Proca.svg";

type LoadingScreenProps = {
  children?: ReactNode;
  title?: string;
  promo?: string;
};

const LoadingScreen: FC<LoadingScreenProps> = (props) => {
  const promoSize = props.children ? 5 : 7;

  return (
    <>
      <ProcaTopLeft />
      <EntryFrame poweredby loading={!props.children} title={props.title}>
        <Grid container>
          {props.children && (
            <Grid size={{ sm:7 }}>
              <Outlet />
            </Grid>
          )}
          {!props.children && (
            <>
              <Grid size={{ sm:4 }}>
                <Proca />
              </Grid>
              <Grid size={{ sm:1 }}></Grid>
            </>
          )}
          <Grid size={{ sm: promoSize }}>
            {props.promo ? (
              <Box ml={2}>{props.promo}</Box>
            ) : (
              <List>
                <Li>Privacy friendly</Li>
                <Li>Lightning fast</Li>
                <Li>Seamlessly efficient</Li>
              </List>
            )}
          </Grid>
        </Grid>
        <Box
          ml={1}
          mt={4}
          fontSize={13}
          fontWeight="fontWeightLight"
          color="text.secondary"
        >
          Trusted as the most privacy friendly campaigning tool by over 350
          nonprofit organisations
        </Box>
      </EntryFrame>
    </>
  );
};

const Li = (props) => (
  <ListItem>
    <ListItemIcon>
      <CheckCircleOutlineIcon />
    </ListItemIcon>
    {props.primary || props.children}
  </ListItem>
);

export default LoadingScreen;
