import { Box, Typography } from "@mui/material";
import {FC} from "react";
import EntryFrame from "src/components/common/Dialog";
import { useProcaStore } from "src/store";

const LoadingScreen: FC = () => {
  const { signOut } = useProcaStore();

  return (
    <EntryFrame poweredby>
      <Box sx={{ m: 2 }}>
        <Typography variant="h4" onClick={() => signOut()}>
          410=350
        </Typography>
      </Box>
    </EntryFrame>
  );
};

export default LoadingScreen;

