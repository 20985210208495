import { FC, ReactNode, useEffect } from "react";
import { supabase } from "src/lib/supabase";
import { useProcaStore } from "src/store";
import { useCurrentUserOrgsQuery, useResetApiTokenMutation } from "src/codegen/types";
import LoadingScreen from "../layout/LoadingScreen";

type Props = {
  children?: ReactNode;
};

const AuthProvider: FC<Props> = ({ children }) => {
  const { session, authenticated, ensureSignIn, setSSOSession, signOut, determineOrg } = useProcaStore();
  const resetApiToken = useResetApiTokenMutation();

  //react-query V4
  const { data: user, isSuccess, error: currentOrgError } = useCurrentUserOrgsQuery(
    {},
    {
       enabled: authenticated,
    }
  );

  //  // react-query V5 (not working for sign out)
  // const queryVariables = authenticated ? {} : null;
  // const { data: user, isSuccess, error: currentOrgError } = useCurrentUserOrgsQuery(queryVariables);

  useEffect(() => {
    if (user) {
      determineOrg(user.currentUser)
    }
  }, [user, determineOrg])

  // Supabase authentication listener
  useEffect(() => {
    setSSOSession();
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_OUT") {
        signOut();
      } else if (session) {
        setSSOSession(session);
      }
    });

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, [setSSOSession, signOut]);

  useEffect(() => {
    ensureSignIn(session);
  }, [ensureSignIn, session]);

  useEffect(() => {
    if (currentOrgError?.message.includes("JWT expired")) {
      console.log("JWT expired")
      window.location.reload();
    }
  }, [currentOrgError]);

  useEffect(() => {
    Object.assign(window, {
      reset_api_token: async () => {
        resetApiToken.mutate(
          {},
          {
            onSuccess: (data) =>
              console.log(`Reset API token to ${data.resetApiToken}`),
            onError: (err) => {
              console.error("Failed to refresh token in auth token", err);
            }
          }
        );
      },
      whoami: async () => {
        console.log("user", user);
      },
    });
  }, [resetApiToken]);

  if (!isSuccess) return <LoadingScreen/>;

  return <>{children}</>;
};

export default AuthProvider;
