import { useProcaStore } from "src/store";

/**
 * hook used in generated queries/mutations for Proca server.
 * Fetcher for react-query to use our authenticated URQL client (instead of banal fetch)
 * */

const queryType = (query: string): "query" | "mutation" | "subscription" => {
  query = query.trim();
  if (query.indexOf("query") === 0) return "query";
  if (query.indexOf("mutation") === 0) return "mutation";
  if (query.indexOf("subscription") === 0) return "subscription";
  throw new Error(`Should not happen: GraphQL query of unknown type: ${query}`);
}

export const useUrqlFetcher = <TData, TVariables extends object>(
  query: string,
  _options?: RequestInit["headers"]
): ((variables?: TVariables) => Promise<TData>) => {
  const { client } = useProcaStore();

  // we need to figure out if this is query or mutation or subscription
  const qt = queryType(query);
  if (qt === "subscription")
    throw new Error("ReactQuery does not support subscriptions");
  const method = qt === "query" ? client.query : client.mutation;

  return async (variables?: TVariables) => {
    const { data, error } = await method(query, variables).toPromise();

    if (error) {
      throw error;
    }

    return data;
  };
};

