import {
  Avatar,
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  useTheme,
} from "@mui/material";
import { FunctionComponent, ReactNode, Suspense } from "react";
import { ProcaLogo } from "src/lib/icons";

type Props = {
  children?: ReactNode;
  footer?: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false; // todo marcin: how to inherit it?
};

const StyledEntryFrame: FunctionComponent<Props> = (props) => {
  const theme = useTheme(); // Hook to access theme

  return (
    <Container component="main" maxWidth={props.maxWidth || "sm"}>
      <Paper
        sx={{
          minWidth: theme.breakpoints.values.sm,
          backgroundColor: theme.palette.background.default,
          margin: theme.spacing(4),
          padding: theme.spacing(2),
          [theme.breakpoints.down("sm")]: {
            margin: 0,
            padding: 0,
          },
        }}
        elevation={3}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <Box
              sx={{
                margin: theme.spacing(4, 0),
                [theme.breakpoints.down("sm")]: {
                  margin: 0,
                },
              }}
            >
              <a href="/">
                <Avatar >
                  <ProcaLogo />
                </Avatar>
              </a>
            </Box>
          </Grid>
          <Suspense fallback={<p>...</p>}>
            <Grid>{props.children}</Grid>
          </Suspense>
          {props.footer && (
            <Grid>
              <Box my={4}>{props.footer}</Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default StyledEntryFrame;
