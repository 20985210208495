import { create } from "zustand";

type PageTitleState = {
  pageTitle: string;
  setPageTitle: (title: string) => void;
};

export const useNavStore = create<PageTitleState>((set) => ({
  pageTitle: "Proca dashboard", // default title
  setPageTitle: (title: string) => set({ pageTitle: title }),
}));
