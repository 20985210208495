import {
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as FixQuoLogo } from "src/images/fixthestatusquo.svg";
import { ReactComponent as ProcaLogo } from "src/images/proca-icon-darkactive.svg";

export const ProcaTopLeft = (props) => {
  return (
    <svg height="100" width="100">
      title="homepage dashboard"
      <circle cx="0" cy="0" r="40" fill="white" />
      <circle cx="34" cy="34" r="40" fill="white" />
      <ProcaLogo x="10" y="10" width="48" height="48" title="Proca logo" />
    </svg>
  );
};

const Dialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiDialog
      fullScreen={fullScreen}
      open
      fullWidth
      scroll="body"
      maxWidth="md"
      hideBackdrop
      PaperProps={{
        elevation: 3,
      }}
    >
      <DialogTitle>
        {props.title}
        {props.poweredby && (
          <Box
            sx={{
              textAlign: 'right',
              position: 'absolute',
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: 'text.secondary',
              fontSize: 13,
            }}
          >
            powered by <FixQuoLogo style={{ display: 'inline', maxWidth: 160, verticalAlign: 'middle' }} />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      {props.loading && <LinearProgress />}
    </MuiDialog>
  );
};

export default Dialog;
