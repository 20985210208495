import { Box, Button } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useProcaStore } from "src/store";
import { useAcceptInviteMutation, OrgFieldsFragment } from "src/codegen/types";

interface Props {
  children?: any;
};

interface Problem extends Props {
  problem: string;
}
type Params = {
  code: string;
  email: string;
};

const UserJoinOrg: FC<Props> = (_props) => {
  const { setCurrentOrgName } = useProcaStore();
  const [org, setOrg] = useState<OrgFieldsFragment>();
  const [problem, setProblem] = useState<string>();

  const location = useLocation();
  const params: Params | null = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const email = query.get("email");
    return code && email ? { code, email } : null;
  }, []);

  useEffect(() => {
    if (!params) setProblem("Link is invalid");
  }, [params]);

  const CampaignsLink: FC<{}> = () => {
    return (
      <Button variant="contained" color="primary" href="/campaign">
        See our campaigns!
      </Button>
    );
  };

  const JoinSuccess: FC<Props> = ({ children }) =>
    !org ? (
      <>{children}</>
    ) : (
      <Box>
        <h5>You joined the {org.title}</h5>
        <p>Welcome to the team!</p>
        <CampaignsLink />
      </Box>
    );



  const JoinFailure: FC<Problem> = ({ children, problem }) =>
    !problem ? (
      <>{children}</>
    ) : (
      <Box>
          <h5>We cannot add you - {problem}</h5>
          {(problem.includes("You are already in that team")) &&
          ( <Button variant="contained" color="primary" href="/org">
            Return to homepage
          </Button>)}
      </Box>
    );

  const acceptInviteMutation = useAcceptInviteMutation();

  useEffect(() => {
    if (params) {
      acceptInviteMutation.mutate(params, {
        onError: (error) => {
          if (error.graphQLErrors[0].extensions.code === "expired") {
            setProblem("Link was already used");
          } else if (error.graphQLErrors[0].message === "staffer_exists") {
            setProblem("You are already in that team.");
          } else {
            setProblem(error.message);
          }
        },
        onSuccess: (data) => {
          const o = data.acceptUserConfirm.org;
          setOrg(o);
        }
      });
    }
  }, [params]);

  useEffect(() => {
    if (org) setCurrentOrgName(org.name);
  }, [org])


  if (problem) return <JoinFailure problem={problem} />
  return <JoinSuccess />
}

export default UserJoinOrg;
