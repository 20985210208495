import { lazy, Suspense } from "react";
import { CookiesProvider } from "react-cookie";
import { Route, Routes } from "react-router-dom";
import LoadingScreen from "src/components/layout/LoadingScreen";
import SignIn from "src/components/user/SignIn";
import UserJoinOrg from "src/components/user/UserJoinOrg";
import EntryFrame from "src/components/layout/EntryFrame";
import LoadErrorBoundary, { LoadError } from "src/components/LoadErrorBoundary";
import Zen from "src/components/Zen";
import AuthProvider from "./components/user/AuthProvider";
import { useProcaStore } from "./store";

const AppLayout = lazy(() => import("src/components/layout/AppLayout"));
const OnboardWizard = lazy(() => import("./components/onboarding/Wizard"));

function loading() {
  return (
    // XXX have some nice animation on loading - make the user feel it is working
    <LoadingScreen />
  );
}

const App = () => {

  const { currentOrgName } = useProcaStore();

  return (
    <CookiesProvider>
        <Suspense fallback={loading()}>
          <Routes>
            <Route path="/*"
              element={
                <LoadErrorBoundary>
                  <AuthProvider>
                    {currentOrgName ?
                      <AppLayout />
                      :
                      <LoadError error={{ message: "Current org undefined", name: "Unauthorized" }} />
                    }
                    </AuthProvider>
                </LoadErrorBoundary>
              }
            />
            <Route path="auth"
              element={
                <LoadingScreen
                  title="Log in safely"
                  promo="Proudly powering progressive coalitions and nonprofit organisations' campaigns since 2020 with over 4M actions taken in 39 languages"
                  children={true}
                />} >
              <Route path="sign_in" element={<SignIn />} />
            </Route>
            <Route path="/zen" element={<Zen />}/>
            <Route path="/welcome/:campaign"
              element={<OnboardWizard />}
            />
            <Route
              path="/join/:orgname"
              element={
                      <AuthProvider>
                          <EntryFrame>
                            <UserJoinOrg />
                          </EntryFrame>
                      </AuthProvider>
                                    }
            />
          </Routes>
        </Suspense>
      </CookiesProvider>
    );
}

export default App;